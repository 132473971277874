import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
// import flickity from 'flickity'
import Flickity from 'react-flickity-component'
import Fade from 'react-reveal/Fade';
import SVG, { Props as SVGProps } from 'react-inlinesvg';

import Layout from "../components/layout"
import SEO from "../components/seo"

import arrow from "../images/arrow.svg"

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHomepage {
          edges {
            node {
              siteName
              headline
              featuredProjects {
                title
                slug
                directedBy
                writtenBy
                starring
                trailer {
                  file {
                    url
                  }
                }
              }
              aboutUsImage {
                fluid(maxWidth: 1024) {
                  ...GatsbyContentfulFluid
                }
              }
              aboutUsImageCaption
              aboutUsCopy {
                aboutUsCopy
              }
              babeNationReviews {
                copy {
                  copy
                }
                author
                authorLogo {
                  file {
                    url
                  }
                }
                link
              }
            }
          }
        }
      }
    `
  );

  const featuredCarousel = useRef(null);
  useEffect(() => {
    featuredCarousel.current.flkty.on('change', e => {
      if(typeof document !== 'undefined') {
        const dots = Array.from(document.getElementsByClassName("dot"));
        dots.forEach((dot, dot_index) => {
          if(e === dot_index) {
            dot.classList.add("selected");
          } else {
            dot.classList.remove("selected");
          }
        });
      }
    });
  }, []);

  const customButtonNav = (selected_index) => {
    featuredCarousel.current.flkty.select(selected_index);

    if(typeof document !== 'undefined') {
      const dots = Array.from(document.getElementsByClassName("dot"));
      dots.forEach((dot, dot_index) => {
        if(selected_index === dot_index) {
          dot.classList.add("selected");
        } else {
          dot.classList.remove("selected");
        }
      });
    }
  }

  const pageContent = data.allContentfulHomepage.edges[0].node;

  return(
    <Layout>
      <SEO title="Home" description="Championing female and emerging voices and stories." />
      
      <section id="landing" className="bg-brown vh-100">
        <div className="d-flex w-100 h-100 flex-column justify-content-end">
          <Container className="py-5 headline-container w-100 h-100 d-flex align-items-end">
            <Row className="w-100">
              <Col xs={{ span: 12 }}>
                <Fade bottom>
                  <p className="headline color-pink mb-0">{ pageContent.headline }</p>
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container fluid className="py-5 headline-arrow-container position-absolute w-100 h-100 d-flex align-items-end justify-content-end">
            <Row>
              <Col>
                {/* <img className="down-arrow" src={ arrow } /> */}
                <a href="#featured" className="down-arrow-link">
                  <SVG className="down-arrow" src={arrow} />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      
      <section id="featured" className="bg-brown position-relative">
        <Flickity ref={featuredCarousel} options={{ autoPlay: 5000, wrapAround: false, prevNextButtons: false, pageDots: false }}>
          {
            pageContent.featuredProjects.map((featuredProject, index) => (
              <div className="featured-project w-100" key={`featured-project-${index}`}>
                <div className="feature-video d-flex align-items-center justify-content-center">
                  <video className="project-video" autoPlay={ true } playsInline muted loop controls={ false }>
                    <source src={ `https:${featuredProject.trailer.file.url}` } type="video/mp4" />
                  </video>
                  <div className="feature-video-overlay"/>
                </div>
                
                <Container className="py-4 vh-100">
                  <div className="d-flex flex-column justify-content-end justify-content-lg-between h-100">
                    <Row>
                      <Col lg={{ span: 5 }} className="pr-lg-0">
                        <div className="feature-title color-pink">
                          <h2 className="mb-3">{ featuredProject.title }</h2>
                        </div>
                      </Col>
                      <Col lg={{ span: 7 }} className="pl-lg-5">
                        <Row>
                          <Col lg={{ span: 3 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                            <div className="details color-pink mb-4 mb-lg-0">
                              <p className="mb-0">DIRECTED BY</p>
                              <p className="mb-0">{ featuredProject.directedBy }</p>
                            </div>
                          </Col>
                          <Col lg={{ span: 3 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                            <div className="details color-pink mb-4 mb-lg-0">
                              <p className="mb-0">WRITTEN BY</p>
                              <p className="mb-0">{ featuredProject.writtenBy }</p>
                            </div>
                          </Col>
                          <Col lg={{ span: 6 }} className="mt-lg-3 mt-xl-4 pr-lg-0">
                            <div className="details color-pink mb-4 mb-lg-0">
                              <p className="mb-0">STARRING</p>
                              <p className="mb-0">{ featuredProject.starring }</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="d-block d-md-none feature-link">
                              <div className="div-next d-flex justify-content-end">
                                <Link className="next-project" to={ featuredProject.slug }>
                                  {/* <img className="d-block d-md-none next-arrow-mobile" src={ arrow } /> */}
                                  <SVG className="d-block d-md-none next-arrow-mobile down-arrow" src={arrow} />
                                  <div className="next-arrow-copy d-inline-block details">See project</div>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <Container fluid className="py-4 d-none d-md-flex next-project-container position-absolute w-100 h-100 align-items-end justify-content-end">
                  <Row>
                    <Col>
                      <div className="feature-link">
                        <div className="div-next d-flex justify-content-end">
                          <Link className="next-project" to={ featuredProject.slug }>
                            <div className="next-arrow-copy d-inline-block details">See project</div>
                            {/* <img className="d-none d-md-block next-arrow" src={ arrow } /> */}
                            <SVG className="d-none d-md-block next-arrow down-arrow" src={arrow} />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            ))
          }
        </Flickity>
        <div className="custom-flickity-nav position-absolute w-100 h-100">
          <Container className="h-100 py-4">
            <Row className="h-100">
              <Col className="d-flex flex-column justify-content-end">
                <div className="dots">
                  {
                    pageContent.featuredProjects.map((featuredProject, index) => 
                      <div onClick={((e) => customButtonNav(index))} className={`dot ${ index === 0 ? "selected" : "" }`} key={`custom-carousel-nav-${index}`} />
                    )
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      
      <section id="about" className="bg-pink py-3 py-md-5">
        <Fade bottom>
          <Container fluid className="px-3 px-md-5">
            <Row>
              <Col>
                <p className="details position-absolute color-brown about-tag d-inline-block mb-0">About us</p>
              </Col>
            </Row>
          </Container>
        </Fade>
        <Container>
          <Row>
            <Col className="pl-md-5" xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 0 }}>
              <Fade bottom>
                <Img className="about-image mt-5" fluid={ pageContent.aboutUsImage.fluid } />
                <p className="about-image-desc color-brown details mt-4 mb-4 mt-md-4 mb-md-4 text-center text-md-left">{ pageContent.aboutUsImageCaption }</p>
              </Fade>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 0 }} className="pl-md-0 d-flex align-items-center">
              <Fade bottom>
                <p className="about-copy color-brown copy mt-2 mt-md-0 mb-md-0">{ pageContent.aboutUsCopy.aboutUsCopy }</p>
              </Fade>
            </Col>
          </Row>
          <Row>
          </Row>
        </Container>
      </section>
      
      <section id="testimonials" className="bg-pink py-5">
        <Flickity options={{ prevNextButtons: false, pageDots: false, autoPlay: true, wrapAround: true }}>
          {
            pageContent.babeNationReviews.map((review, index) => (
              <div className="mx-3 p-4 testimonial text-center" key={`babe-nation-review-${index}`}>
                <div className="testimonial-author-img d-flex align-items-center justify-content-center">
                  <img src={`https:${review.authorLogo.file.url}`} />
                </div>
                <p className="testimonial-date color-white mt-5">AUGUST 30, 2019</p>
                <p className="testimonial-copy color-white">"{ review.copy.copy.length <= 50 ? review.copy.copy : `${review.copy.copy.slice(0, 50)}...` }"</p>
                {
                  review.link ? 
                  <a href={review.link} className="testimonial-link pb-4 pr-4">Read more</a> :
                  ""
                }
              </div>
            ))
          }
        </Flickity>
      </section>
    </Layout>
  )
}

export default IndexPage
